<template>
    <Plotly :data="data" :layout="layout" :display-mode-bar="false"></Plotly>
</template>

<script>
import { Plotly } from "vue-plotly";

export default {
  name: "PieChart",
  props: {
    pieData: {},
  },
  components: {
    Plotly,
  },
  mounted() { },
  data() {
    let values = [];
    let labels = [];
    let ultimatecolor = [];

    this.pieData.forEach((item) => {
      values.push(item.price);
      labels.push(item.labels);
      ultimatecolor.push(item.color);
    });

   
    return {
      data: [
        {
          values: values,
          labels: labels,
          marker: {
            colors: ultimatecolor,
          },
          type: "pie",
          outsidetextfont: { color: "transparent" },
    textfont: {
        family: 'Montserrat, Helvetica, sans-serif',
        size: 14,
        color: '#fff'
    }          
        },
      ],
      layout: {
        // height: 330,
        // width: 330,
        autosize: true,
        padding: 0,
        margin: { t: 0, b: 0, l: 0, r: 0 },
        plot_bgcolor: "rgba(0, 0, 0, 0)",
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        showlegend: false,
        textposition: 'inside',
        textinfo: 'none',
        outsidetextfont: { color: 'transparent' },
        font: {
          family: 'Montserrat',
          size: 15,
          weight: 1000,
          color: 'white',


        },
      },
    };
  },
  methods: {},
};
</script>

<style>

</style>
